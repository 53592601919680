export var tableField = [
  {
    label: '行号',
    code: 'index',
    type: 'function',
    width: '80',
    handle: (index) => {
      return index + 1
    }
  },
  { label: '集团', code: 'company', type: 'input', width: '' },
  { label: '采购订单号', code: 'orderCode', type: 'input', width: '' },
  // { label: '更改类型', code: 'changeType', type: 'input', width: '' },
  {
    label: '更改类型',
    code: 'changeType',
    type: 'function',
    width: '',
    handle: (index, data) => {
      return data.changeType === 'U' ? '修改数据' : data.changeType === 'I' ? '新增数据' : data.changeType === 'E' ? '保存数据' : '删除数据'
    }
  },
  { label: '更新数据', code: 'tableFiled', type: 'input', width: '' },
  { label: '更改数据所在表', code: 'tableName', type: 'input', width: '' },
  { label: '变更前', code: 'beforeValue', type: 'input', width: '' },
  { label: '变更后 ', code: 'afterValue', type: 'input', width: '' },
  { label: '更新时间', code: 'changeDate', type: 'input', width: '120' }

]

export var tableData = []
