export var tableField = [
  { label: '行号', code: 'orderRow', type: 'input', width: '' },
  { label: '物料编号', code: 'materialCode', type: 'input', width: '200' },
  { label: '物料描述', code: 'materialName', type: 'input', width: '300' },
  { label: '含税单价', code: 'taxPrice', type: 'input', width: '100px' },
  { label: '含税总价', code: 'taxTotalPrice', type: 'input', width: '100px' },
  { label: '美元单价', code: 'dollarPrice', type: 'input', width: '100px' },
  { label: '币种', code: 'currencyType', type: 'input', width: '100px' },
  { label: '税率', code: 'taxCode', type: 'input', width: '100px' },
  {
    label: '数量/单位',
    code: 'macIssue',
    type: 'function',
    width: '150px',
    handle (index, row) {
      return `${row.number}/${row.materialUnit}`
    }
  },
  { label: '备注', code: 'remark', type: 'input', width: '' },
  {
    label: '组件清单',
    code: 'act',
    width: '100px',
    type: 'action',
    actionData: [
      {
        actionLabel: '查看',
        action: 'tableAction',
        operationButton: 'see'
      }
    ]
  },
  { label: '收货地址', code: 'outProviderAddr', type: 'input', width: '100px' }
]

export var tableData = []

export var formData = {
  orderId: '',
  sellOrderCode: '',
  salesmanName: '',
  userId: '',
  buyOrderCode: '',
  companyName: '',
  orderStatus: '',
  providerCode: '',
  relationContract: '',
  releaseBeginTime: '',
  releaseEndTime: ''
}

export var navData = [{
  label: '订单信息',
  name: 'info',
  id: 1
}, {
  label: '变更记录',
  name: 'notice',
  id: 2
}]
