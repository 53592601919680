<template>
<!-- 订单详情的订单信息tab子组件 -->
  <global-table :tableField="tableField" max-height="350px" :tableData="tableData" ></global-table>
</template>

<script>
import GlobalTable from '@/components/actionList/GlobalTable.vue'
import { tableField, tableData } from './js/rechangeRecord'
import { request } from '@/assets/js/http.js'
export default {
  components: { GlobalTable },
  name: 'ReChangeRecord',
  data () {
    return {
      tableField: tableField,
      tableData: tableData
    }
  },
  mounted () {
    this.editQuotation()
  },
  methods: {
    editQuotation () {
      var orderCode = this.$route.query.orderCode
      request('/api/order/order/getOrderChangeDetailInfoByOrderCode?orderCode=' + orderCode, 'POST').then((response) => {
        if (response.code === '200') {
          this.tableData = response.data
        }
      })
    }
  }
}

</script>
<style scoped lang="scss">

</style>
