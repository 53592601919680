<template>
  <div class="mainform">
    <div class="mainHeader">订单详情
      <span @click="back" class="back">返回></span>
    </div>
    <div class="form">
      <table-tab :TabData="navData" @tabSwitch="handleClick" defaultData="info"></table-tab>
      <re-order-information ref="orderInformation" v-show="detailItem" class="content bascform"></re-order-information>
      <re-change-record v-show="!detailItem" class="content bascform"></re-change-record>
      <div v-show="detailItem">
        <div class="colorstyle"></div>
        <div class="detailtitle">明细项 </div>
        <global-table :tableField="tableField" max-height="350" :tableData="tableData" @tableAction="tableAction"></global-table>
        <el-form v-show="detailItem" label-width="110px" class="content bascform" >
          <div class="tableBottom">
            <span>总计数量：{{totleNember}}</span>
            <span>总计金额：￥{{totlePrice}}</span>
          </div>
        </el-form>
      </div>
    </div>
    <dialog-box :dialogShow="seedialogShow" dialogWidth="50%" @handleClose='seehandleClose' title="组件清单" :showData="showData" componentName="ReComponentListdialog"
        :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{label:'确认',action:'handleClose', type:'primary', size:'mini'}]">
    </dialog-box>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { requestForm } from '@/assets/js/http.js'
import { tableField, tableData, navData } from './js/reorderDetail'
import ReChangeRecord from './ReChangeRecord.vue'
export default {
  name: 'ReorderDetail',
  components: { ...Component.components, ReChangeRecord },
  data () {
    return {
      tableField: tableField,
      tableData: tableData,
      navData: navData,
      detailItem: true, // 明细项是否显示
      seedialogShow: false, // 明细项查看的弹窗
      showData: {},
      buttonData: [],
      totleNember: '', // 总数量
      totlePrice: '' // 总金额
    }
  },
  mounted () {
    this.OrderInfordetail()
  },
  methods: {
    OrderInfordetail () { // 明细项
      var orderCode = this.$route.query.orderCode
      requestForm('/api/order/order/getOrderDetailInfoByOrderCode?orderCode=' + orderCode, 'POST', '').then((response) => {
        if (response.code === '200') {
          this.tableData = response.data.orderDetailInfoList
          this.totleNember = response.data.totleNember// 总数量
          this.totlePrice = response.data.totlePrice// 总金额
        }
      })
    },
    seehandleClose () {
      this.seedialogShow = false
    },
    handleClick (data) { // tab点击切换 隐藏明细项
      if (data.props.name !== 'info') {
        this.detailItem = false
      } else {
        this.detailItem = true
      }
    },
    tableAction (index, data, act) { // 明细项查看按钮弹框
      this.seedialogShow = true
      this.showData = data
    },
    edithandleClose () {
      this.dialogShow = false
    },
    // 返回
    back: () => {
      window.history.back()
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/form.scss';
  @import '@/assets/css/elForm.scss';
   .tableList {
    padding-left: 0px;
    padding-right: 0;
  }
  .colorstyle{
    height: 1px;
    width: 1543px;
    background: #eee;
  }
  .action{
    margin-top: 40px;
  }
  .detailtitle{
    font-size: 14px;
    color: #666;
    font-weight: 600;
    margin: 50px 0 20px 0px;
  }
  .tableBottom{
    color: #666;
    font-size: 14px;
  }
</style>
